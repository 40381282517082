import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCategories, getCategoryVideos } from '../../actions/filterActions';
import Slider from 'react-slick';
import ArrowRight from '../icons/Arrow_right.svg'
import arrowLeft from '../icons/Arrow_left.svg'
import { useNavigate } from 'react-router-dom';
import NATURE from '../icons/Nature.svg'
import ADVENTURE from '../icons/Adventure.svg'
import ARCHITECTURE from '../icons/Architecture.svg'
import SEASONS from '../icons/Seasons.svg'
import RURAL from '../icons/Rural.svg'
import INWARD from '../icons/Inward.svg'
import HOLIDAYS from '../icons/Holidays.svg'
import CULTURES from '../icons/Cultures.svg'
import CONCERTS from '../icons/Concerts.svg'
import CHRISTMAS from '../icons/Christmas.svg'
import URBAN from '../icons/Urban.svg'
import WILDLIFE from '../icons/Wildlife.svg'


const Categories = (props) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [categoryRef, setCategoryRef] = useState()

    const { user, userLoading} = useSelector(state => state.user)

    const {categories} = useSelector(state=>state.categories)

    useEffect(()=>{
        dispatch(getCategories())
    },[dispatch])


    const clickHandler = (tag) => {
        if (user) {
            navigate(`/category/${tag.title}/${tag.id}`)
        } else {
            navigate('/login')
        }
    }

    const settings = {
        className: "slider variable-width",
        arrows: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 3,
        variableWidth: true,
      };


    return (
        <>  
            <div className='col-12 position-relative d-flex justify-content-center align-items-center'>
                <div 
                    className= "d-flex align-items-center position-absolute justify-content-end visible"
                    id="button-prev"
                    role = 'button'
                    style={{left:'2vw'}}
                    onClick={categoryRef?.slickPrev}>
                        <img src = {arrowLeft} alt = ''
                        />
                </div>
                
                <div className = 'col-11 py-5 position-relative'>
                        { categories && categories.length ?
                        <Slider {...settings} ref={setCategoryRef} slickGoTo>
                            { 
                                categories.map((tag, index) => (
                                    <div
                                        key = {index}
                                        role = 'button'
                                        className='px-1' style={{width:`${tag.length*5}px`}}
                                        onClick={()=>clickHandler(tag)}
                                    >
                                        <div
                                            className='bg-secondary rounded px-4 py-2 d-flex'
                                            style={{fontWeight:500}}
                                        >
                                            <img src = {`https://img.cugate.com/?o=CUTV_TAGICON&i=${tag.id}&s=mega`} width='20' alt = 'bb'/>
                                            <div className = 'fs-3 ps-2 text-info'>{tag.title.toUpperCase()}</div>
                                        </div>

                                    </div>
                                ))
                            } 
                        </Slider> :''
                        } 
                </div>
                <div  className="d-flex align-items-center justify-content-start position-absolute "
                    role = 'button'
                    id="button-next" 
                    style={{right:'2vw'}}
                    onClick={categoryRef?.slickNext}>
                    <img src={ArrowRight} 
                        alt = ''       
                    />
                </div> 
            </div>
        </>
    );
};

export default Categories;