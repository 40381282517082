import React, { useEffect, useRef} from 'react';
import LandingPage from './landing/LandingPage';
import { Routes, Route } from 'react-router';
import Home from './Home';
import SearchResults from './results/SearchResults';
import FilterResults from './results/FilterResults';
import UserLayout from './UserLayout';
import GroupPage from './PlaylistGroups/GroupPage';
import PlaylistPage from './PlaylistGroups/PlaylistPage';
import { useDispatch, useSelector } from 'react-redux';
import { listUserPlaylists } from '../actions/PlaylistActions';
import CategoryResults from './results/CategoryResults';
import UserPlaylistPage from './PlaylistGroups/UserPlaylistPage';

function UserContent() {
    const newPlaylistsRef = useRef()
    const myPlaylistsRef = useRef()

    const dispatch = useDispatch()

    const { userloading, user } = useSelector(state=>state.user)

  useEffect(()=>{
    if(user && user.id){
      dispatch(listUserPlaylists())
    }
  },[user])

  return (
    <>
      <Routes>
        <Route element = { <UserLayout
            myPlaylistsRef = {myPlaylistsRef}
            newPlaylistsRef = {newPlaylistsRef}
        /> } >
            <Route exact path = '/' element = {
            <LandingPage
                newPlaylistsRef = {newPlaylistsRef}
            /> }
            />
            <Route path = '/home' element = {
                <Home
                    myPlaylistsRef = {myPlaylistsRef}
                    user = {user}
                />
                }
            />
            <Route path = '/search_results/:keyword/' element = {
                <SearchResults
                    user = {user}
                    userloading = {userloading}
                />
                }             
            />
            <Route path = '/category/:category_title/:category_id' element = {
                <CategoryResults
                    user = {user} 
                    userloading = {userloading}                />
                }             
            />
            <Route path = '/filter_results/:filterList' element = {
                <FilterResults
                    user = {user}
                    userloading = {userloading}
                />
                }             
            />
            <Route path = '/group/:group_id/:group_title' element = {
                <GroupPage
                    user = {user}
                    userloading = {userloading}
                />
                }             
            />
            <Route path = '/playlist/:playlist_id/:playtlist_title' element = {
                <PlaylistPage
                    user = {user}
                    userloading = {userloading}
                />
                }             
            />
            <Route path = '/myPlaylist/:playlist_id/:playtlist_title' element = {
                <UserPlaylistPage
                    user = {user}
                    userloading = {userloading}
                />
                }             
            />
        {/* <Route path="*"  element = {
          <Navigate to="/" />
        }/> */}
        </Route>
      </Routes>
    </>
  )
}

export default UserContent;