import React, { useEffect, useState  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listPlaylistVideos } from '../../actions/PlaylistActions'
import SidePlaylistComponent from './SidePlaylistComponent'
import Slider from '../slides/Slider'
import nextArrow from '../icons/nextArrow.svg'
import prevArrow from '../icons/prevArrow.svg'
import PlayerHeader from './PlayerHeader'

const BottomPlaylists = (props) => {

    const playlistVideosList = useSelector(state => state.userPlaylists)

    const dispatch = useDispatch()

    const [bottomSliderRef, setBottomSliderRef] = useState()

    const [sliderVisible, setSliderVisible] = useState(true)

    const [items, setItems] = useState()

    let playlist_id = parseInt(props.searchParams.get('p')) ? parseInt(props.searchParams.get('p')) : localStorage.getItem('playlist_id') ? localStorage.getItem('playlist_id') : 54

    const { playlistVideos, playlist_user_id, active_pl_id } = playlistVideosList

    
    useEffect(()=>{
        dispatch(listPlaylistVideos(playlist_id))
    },[dispatch])

    useEffect(()=>{
      if(playlistVideos && playlistVideos.length){
        setItems(playlistVideos)
      }

    },[playlistVideos])

    useEffect(()=>{
      if(active_pl_id){
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), p:active_pl_id})
      }
    },[active_pl_id])



      const videoClickHandler = (v) => {
        props.setPlaying(true)
        props.setVideo(v)
        localStorage.setItem('video_id', v.id)
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:v.id})
    }

    return (
        <>
        {
            <PlayerHeader
                setVideo = {props.setVideo}
                searchParams = {props.searchParams}
                setSearchParams = {props.setSearchParams}
                setSliderVisible = {setSliderVisible}
                sliderVisible = {sliderVisible}
                menuVisible = {props.menuVisible}
                playing = {props.playing}
            />
        }
            <div className = 'w-100 position-relative bottom-0 overflow-hidden d-flex justify-content-end align-items-end m-0'
                style={props.width>990 ? !sliderVisible ? {} : {height:"10vw"} :{height:'150px'}} 
            >
            {
                sliderVisible &&    
                <div 
                    className={!props.playing || props.menuVisible ? 'w-100 py-2 visible position-absolute bottom-0' : 'w-100 py-2 position-absolute hidden mb-0'}
                    onClick={(e)=>{e.stopPropagation()}}
                    style={{backgroundColor:"#07294C80", zIndex:'150'}}
                    >
                    <Slider
                        num = {6}
                        type = {4}
                        showTitle = {false}
                        tvData={playlistVideos}
                        slideClickHandler={videoClickHandler}
                    />
                </div>
            }
            </div>
        </>
    );
};

export default BottomPlaylists;