import React, { useEffect, useRef, useState } from 'react'
import GroupsComponent from './PlaylistGroups/GroupsComponent'
import Playlists from './playlists/Playlists'
import VideoPlayer from './videoPlayler/VideoPlayer'
import {useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AnalyzeComponent from './analyze/AnalyzeComponent'
import { getActiveVideos } from '../actions/videoActions'
import { listPlaylistVideos, getPlaylistGroups } from '../actions/PlaylistActions'
import {useSearchParams} from 'react-router-dom'
import Genres from './landing/Genres'
import BottomComponent from './landing/BottomComponent'
import Concerts from './landing/Concerts'

export default function Home(props) {

    const dispatch = useDispatch()
    const playerRef = useRef()
    const [video, setVideo] = useState()
    const [searchParams, setSearchParams] = useSearchParams([])
    const authenticate = useSelector(state => state.user)
    const { user, userLoading} = authenticate 
    const [currentTime, setCurrentTime] = useState(0)
    const navigate = useNavigate()   
    const {activeVideo} = useSelector(state => state.activeVideo)  

    
    useEffect(()=>{
        dispatch(getActiveVideos(searchParams.get('v')))
        dispatch(getPlaylistGroups())
    },[dispatch])

    useEffect(()=>{
        setVideo(activeVideo)
    },[activeVideo])

    const handleReWind = () => {
        setCurrentTime(Math.round(playerRef.current.getCurrentTime()))
    }

    const slideClickHandler = (data) => {
      localStorage.setItem('playlist_id', data.id)
      dispatch(listPlaylistVideos(data.id))
      if(data.video_id){
        dispatch(getActiveVideos(data.video_id))
        setSearchParams({ ...Object.fromEntries([...searchParams]), p:data.id, v:data.video_id})
        localStorage.setItem('video_id', data.video_id)
      } else {
        setSearchParams({ ...Object.fromEntries([...searchParams]), p:data.id}) 
      }
    }


    if(userLoading){
      return (<div>Loading...</div>)
    } else {
      if(user){
      return (
        <div className='w-100 bg-bluedark'>
          {
            video &&
            <VideoPlayer
              video = {video}
              setVideo = {setVideo}
              searchParams = {searchParams}
              setSearchParams = {setSearchParams}
              handleRewind={handleReWind}
              playerRef = {playerRef}
            />
          }

            <AnalyzeComponent
                currentTime = {currentTime}
                video = {video}
              />
            <Playlists
                slideClickHandler = {slideClickHandler}
                searchParams = {searchParams}
                setSearchParams = {setSearchParams}
                myPlaylistsRef = {props.myPlaylistsRef}
                />
            <Concerts
                user = {user}
                slidesToShow = {1}
                slideClickHandler = {slideClickHandler}
            />
            <Genres
                user = {user}
            />
            <GroupsComponent
                sCWHeight={12}
                setVideo = {setVideo}
                slideClickHandler={slideClickHandler}
                searchParams = {searchParams}
                setSearchParams = {setSearchParams}/>
            <BottomComponent
                user = {user}
            />
            <div className = 'w-100 bg-bluedark' style={{height:'150px'}}></div>
        </div>
        ) } else {
          searchParams.get('v') && localStorage.setItem('video_id', searchParams.get('v')) 
          searchParams.get('p') && localStorage.setItem('playlist_id', searchParams.get('p')) 
          searchParams.get('g') && localStorage.setItem('group_id', searchParams.get('g')) 
          navigate('/login')
        }
  }
}


