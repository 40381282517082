import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlaylistVideos,  arrangeVideos } from '../../actions/PlaylistActions'
import { useParams } from 'react-router-dom'
import arrowLeft from '../icons/Arrow_left.svg'
import { useNavigate } from 'react-router-dom';
import UserPlaylistComponent from './UserPlaylistComponent';
import {
    DndContext, 
    closestCorners,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    horizontalListSortingStrategy,
    sortableKeyboardCoordinates,
  } from "@dnd-kit/sortable";


const UserPlaylistPage = () => {

    const {playlist_id, playlist_title} = useParams()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [items, setItems] = useState()
  
    const { loading, playlistVideos, active_pl_id} = useSelector(state => state.userPlaylists)
  
    useEffect(()=>{
  
      dispatch(listPlaylistVideos(playlist_id))
  
    },[dispatch, playlist_id])


    useEffect(()=>{
        if(playlistVideos && playlistVideos.length){
        setItems(playlistVideos)
        }

    },[playlistVideos])


    const sensors = useSensors(
        useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
        }),
        useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
        }),
        useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
        })
    );
      

    const handleDragEnd = (event) => {
      const {active, over} = event;
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.map(item=>item.position).indexOf(active.id);
          const newIndex = items.map(item=>item.position).indexOf(over.id);
          if (active.id>over.id){
              items.forEach(item => {
                  if(item.position < active.id && item.position >= over.id){
                      item.position += 1
                  } else if (item.position === active.id){
                      item.position = over.id
                  }
              });
          } else if (active.id<over.id){
              items.forEach(item => {
                  if(item.position > active.id && item.position <= over.id){
                      item.position -= 1
                  } else if (item.position === active.id){
                      item.position = over.id
                  }
              });
          } 
          return arrayMove(items, oldIndex, newIndex);
        });
        dispatch(arrangeVideos(playlist_id, active.id, over.id))
      }
  }


    return (
        <div className = "w-100 min-vh-100 bg-bluedark d-flex flex-column align-items-center">
            <div className = "w-100 d-flex flex-column align-items-center pb-5">
            { 
                loading ? <div className = "text-info col-12 col-lg-10" >Loading...</div> :
                <>
                    <div className = 'w-100 d-flex justify-content-center pt-5 pb-4'>
                        <div className = 'col-12 px-5 col-lg-10 d-flex'>
                            <img 
                                src = {arrowLeft} 
                                role = 'button'
                                onClick={()=>navigate(-1)}
                                alt = ''/>
                            <div className='ps-3 text-white fs-7 fw-bold'>{playlist_title}</div>
                        </div>
                    </div>
                    <DndContext 
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragEnd={handleDragEnd}
                        >
                        {
                            items && items.length ? 
                            <div className='col-12 col-lg-10 px-4 d-flex flex-row flex-wrap'>
                                <SortableContext items = {items.map(video=>video.position)} strategy={horizontalListSortingStrategy}>
                                    {
                                        items.map((video)=>(
                                            <UserPlaylistComponent
                                                video = {video}
                                                key = {video.id}
                                            />
                                    )) 
                                    }
                                </SortableContext>
                           </div> : ""
                        }
                        </DndContext>
                </>
            }
            </div>  
        
        </div>
    );
};

export default UserPlaylistPage;