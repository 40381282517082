import React from 'react';
import default_cover from '../images/default_cover.jpg'
import but_ON from '../icons/but_ON.svg'
import but_OFF from '../icons/but_OFF.svg'
import { getTemporaryPlaylist } from '../../actions/PlaylistActions';
import { useDispatch } from 'react-redux';

const  ResultComponent = (props) => {

    const dispatch = useDispatch()

    const clickHandler = (video) => {
        dispatch(getTemporaryPlaylist(video.id))
        props.setSelectedVideo(video)
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    const checkHandler = (video_id) => {
        if(props.videoList.includes(video_id)) {
            props.setVideoList(prevArr => prevArr.filter(i => i!==video_id))
        } else {
            props.setVideoList((prevArr)=>{ return[...prevArr, video_id]})
        }
    }

    const get_members = (data) =>{
        let members = ''
    
        if (data.artists && data.artists.length){
          members = data.artists
      }
    
      if (data.composers && data.composers.length){
          let c = data.composers.split(',')
          for (let i in c){
              if (!members.includes(c[i])){
                  members += ', '+ c[i]
              }
          }    
      }
        return members
    }

    return (
        <div className='w-100' onClick={props.checkActive ? ()=>checkHandler(props.result.id) : ()=>clickHandler(props.result)} >
            <div className='position-relative'
            >
                <img src={ `https://img.cugate.com/?o=CUTV_VIDEO&i=${props.result.id}&s=300`} alt = ""
                    onError = {({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src=default_cover
                    }}
                    role='button'
                    className='w-100 px-1'
                    style={
                        {
                            objectFit: 'cover',
                            borderRadius:'0.625vw',
                            aspectRatio: "16/9"
                        }
                    }>
                    </img>
                    <div className='position-absolute text-white rounded px-1 bg-dark'
                        style={{zIndex:"2", bottom:"0.05rem", right:"0.2rem"}}>
                        {toTimeString(props.result.duration)}
                    </div>
                </div>
                <div className = "w-100 d-flex flex-row align-items-start pt-1 pt-lg-3">
                    <div className='d-flex flex-column align-items-center'>
                        <div className = "bg-info align-self-start text-primary text-nowrap rounded-circle fw-bold p-2">CU</div>
                        {
                            props.checkActive ? props.videoList.includes(props.result.id) ? 
                            <img
                                className='mt-3' 
                                role='button'
                                src = {but_ON} 
                                alt = ''
                                /> : 
                            <img 
                                className='mt-3' 
                                role='button'
                                src = {but_OFF} 
                                alt = ''
                                /> : ""
                        }
                    </div>
                    <div
                        className='ms-0 w-100 ms-lg-3 text-wrap px-1 d-flex flex-column'
                        >
                        <h6
                            className='text-white fw-bold overflow-hidden m-0'
                            // style={{height:"auto"}}
                            title={props.result.title}>{props.result.title}</h6>
                            {
                                get_members(props.result).length ? 
                                <h6
                                    className='text-info overflow-hidden'
                                    style={{maxHeight:'40px'}}
                                    title={get_members(props.result)}>{get_members(props.result)}</h6> : ""
                            }
                    </div>
                </div>
        </div>
    );
}

export default ResultComponent;