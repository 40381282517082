import React, {useRef, useEffect, useState} from 'react'
import SidePlaylist from './SidePlaylist'
import VideoNameAndActions from './VideoNameAndActions'
import { useSelector } from 'react-redux'
import PlayerHeader from './PlayerHeader'
import AnalyzeButton from './AnalyzeButton'
import CustomPlayer from './CustomPlayer'


export default function VideoPlayer(props) {
  
  const { playlistVideos } = useSelector(state => state.userPlaylists)
  const videoPlayerRef = useRef()
  

  useEffect(()=>{
    videoPlayerRef.current && videoPlayerRef.current.scrollIntoView({ behavior: "smooth" })
  },[playlistVideos])



  return (
    <div className = 'w-100 position-relative bg-bluedark pb-5 d-flex flex-column justify-content-center align-items-center' ref = {videoPlayerRef}>
      <div className = "w-100 position-relative d-flex flex-column flex-lg-row align-items-start justify-content-center justify-content-lg-between">
        <div className="w-100 d-flex flex-column "
        >
          <div className = 'w-100 d-flex pb-4 pb-lg-0 position-relative d-flex flex-column'>
            { 
                <CustomPlayer   
                    playerRef = {props.playerRef}
                    setPlayerRef= {props.setPlayerRef}
                    video = {props.video}
                    setVideo = {props.setVideo}
                    searchParams = {props.searchParams}
                    setSearchParams = {props.setSearchParams}
                    handleRewind = {props.handleRewind}
                />
            
          }
          <div className = 'd-flex'>
              <VideoNameAndActions  
                video = {props.video}
              />
          </div>
          </div>
        </div>
      </div>
    </div>
  )

}

