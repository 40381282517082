import React from 'react';
// import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter';
import Banner from './Banner';
import './LandingStyles.css'
import { useSelector } from 'react-redux';
import BottomComponent from './BottomComponent';
import Concerts from './Concerts';
import NewVideos from './NewVideos';
import Genres from './Genres';
import Categories from './Categories';
import Groups from './Groups';
import EssentialClassics from './EssentialClassics';
import { useNavigate } from 'react-router-dom';


const LandingPage = (props) => {

    const navigate = useNavigate()

    const {userLoading, user} = useSelector(state=>state.user)

        const slideClickHandler = (item) => {
            localStorage.setItem('playlist_id', item.id)
            localStorage.setItem('video_id', item.video.id)
            localStorage.setItem('group_id', 5)
            if(props.user) {
              navigate(encodeURI(`/home?g=${5}&p=${item.id}&v=${item.video.id}`))
            } else {
              navigate('/login')
            }
          }
    

    return (
        <div>
            <div id = 'landing_body'>
                <Banner
                    user = {user}
                />
                <div style={{ marginTop:'-14vw', zIndex:2, position:'relative'}}>
                    <Categories
                    />
                    <NewVideos
                        title = {'New Videos'}
                        newPlaylistsRef = {props.newPlaylistsRef}
                    />
                    <EssentialClassics
                        user = {user}
                    />
                    <Concerts
                        user = {user}
                        slidesToShow = {1}
                        slideClickHandler = {slideClickHandler}
                    />

                    <Genres
                        user = {user}
                    />
                    <Groups
                        user = {user}
                    />
                    <BottomComponent
                        user = {user}
                    />
                    <LandingFooter/>
                </div>
            </div>
        </div>
        
    );
}

export default LandingPage;