import { Outlet } from "react-router-dom";
import Header from "./header_footer/Header";

const UserLayout = (props) => {

    return (
      <div className="position-relative">
        <Header
            newPlaylistsRef = {props.newPlaylistsRef}
            myPlaylistsRef = {props.myPlaylistsRef}
        />
        <Outlet />
      </div>
    );
  };
  
  export default UserLayout;