import React, {useState} from 'react';
import deleteIcon from '../icons/DeleteItem.svg'
import reset from '../icons/Reset.svg'
import Genres from './Genres'
import Moods from './Moods'
import Artists from './Artists'
import Composers from './Composers'
import Tags from './Tags'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Filter(props) {

    const navigate = useNavigate()

    const [filterList, setFilterList] = useState([])
    const authenticate = useSelector(state => state.user)
    const { user, userLoading} = authenticate 

    const deleteHandler = (item) =>{
        setFilterList((prevArr) => prevArr.filter(i => i !== item))
    }

    const filterGo = () => {
        let lst = filterList.map(fl=>[fl[0], fl[2]].join('!')).join('|')
        if(user){
            navigate(`/filter_results/${encodeURI(lst)}`)
        } else {
            navigate('/login')
        }
    }

    return (
        <div className = 'w-100 pb-2 pb-lg-4 bg-light d-flex justify-content-center'>
            <div className = 'col-12 pt-3 d-flex flex-column px-1 px-lg-5'>
                <div className = 'col-12 d-flex align-items-center align-items-lg-end flex-column flex-wrap flex-lg-row flex-md-row justify-content-center justify-content-md-around justify-content-lg-between'>
                    <Artists
                        index = {0}
                        filterList = {filterList}
                        setFilterList = {setFilterList}
                    />
                    <Composers
                        index = {0}
                        filterList = {filterList}
                        setFilterList = {setFilterList}
                    />
                    <Genres
                        index = {0}
                        filterList = {filterList}
                        setFilterList = {setFilterList}
                    />
                    <Moods
                        index = {0}
                        filterList = {filterList}
                        setFilterList = {setFilterList}
                    />
                    <Tags
                        index = {0}
                        filterList = {filterList}
                        setFilterList = {setFilterList}
                    />
                    <div className = 'd-flex py-2 justify-content-center justify-content-md-start align-items-center' id="find-buttons">
                        <div className='d-flex align-items-center' style={{gap:"0.5rem"}}>
                            <img   
                                style={{width:"2rem", marginLeft:"0.4rem"}}
                                src = {reset}
                                alt = ''
                                role = "button" onClick={()=>setFilterList([])}/>
                            <div className = 'py-2 px-1 bg-secondary text-white rounded-pill d-flex justify-content-center align-items-center' 
                                role = "button" onClick = {()=>{filterGo()}}
                                style={{fontWeight:"500", width:"7rem"}}>
                                Find
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'px-2 position-relative pt-3 d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                    <div className = 'col-12 col-lg-10 d-flex flex-wrap flex-row'>
                        {
                            filterList && filterList.length ? 
                            filterList.map((item, i) => 
                            <div className = "d-flex flex-row align-items-center text-wrap text-lg-nowrap me-2 mb-2" key = {i} >
                                <div className = "fs-sm text-secondary me-1">{item[1]}</div>
                                <img 
                                    role="button"
                                    src = {deleteIcon} 
                                    alt = "" onClick={()=>deleteHandler(item)}/>
                            </div>) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Filter;