import React, { useRef, useEffect } from 'react'
import ReactDom from 'react-dom'


const ModalOverlay = (props) => {

  const modalRef = useRef()

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (modalRef == null || modalRef.current == null || !modalRef.current.contains(event.target)){
        props.setTrigger(false)
      }
    });
  })

  return (props.trigger) ? (
    <div className = "position-fixed rounded bg-transparent" 
        onClick={(e)=>e.stopPropagation()}
        style={{zIndex:200, right:props.right, top:props.top}} 
        ref = {modalRef}>
        {props.content}
    </div>
  ) : ""
}

const Modal = (props) => {
    
  return (
    <>
      {ReactDom.createPortal(
        <ModalOverlay
          content = {props.content}
          trigger = {props.trigger}
          setTrigger = {props.setTrigger}
          top = {props.top}
          right = {props.right}
        />,
        document.getElementById('overlay-root')
        )
      }
    </>
  )
}

export default Modal
