import React, {useEffect, useState, useRef} from 'react';
import ReactPlayer from 'react-player/lazy'
import { useDispatch, useSelector } from 'react-redux'
import { getAnalyzeInfo } from '../../actions/AnalyzeActions'
import PlayerOverlay from './PlayerOverlay';

const CustomPlayer = (props) => {

    const dispatch = useDispatch()

    let time = 0

    const { playlistVideos } = useSelector(state => state.userPlaylists)
    const playerContainerRef = useRef()
    const [videoUrl, setVideoUrl] = useState()
    const [playing, setPlaying] = useState(false)
    const [menuVisible, setMenuVisible] = useState(true)
    const [mouse, setMouse] = useState(false)
    const [sound, setSound] = useState(0.5)
    const [muted, setMuted] = useState(false)
    const [timeDisplay, setTimeDisplay] = useState("00:00")
    const [videoProgress, setVideoProgress] = useState(0)
    const [soundProgress, setSoundProgress] = useState(50) 
    const [quality, setQuality] = useState()

    const onVideoChange = (e) => {
        setVideoProgress(e.target.value)
        let val = props.video.duration*e.target.value/100
        props.playerRef && props.playerRef.current.player.seekTo(val)
        let t = props.playerRef.current?.getCurrentTime()
        t && setTimeDisplay(toTimeString(t)) 
    }
  

    const onSoundChange = (e) => {
        setSound(e.target.value/100)
        setSoundProgress(e.target.value)
        setMuted(false)
    }


    useEffect(()=>{
        if (props.video && props.video.video_bunny_id){
            setVideoUrl(props.video.scripted_bunny_path)
            setTimeDisplay("00:00")
        }
    },[props.video])


    useEffect(()=>{
        if(!mouse){
            const identifier = setTimeout(() => {
                setMenuVisible(false)
            }, 3000);
    
            return () => {
                clearTimeout(identifier)
            }
        } else {
          setMenuVisible(true)
        }
      },[mouse])

    const endHandler = () => {
        if (playlistVideos && playlistVideos.length){
          let index = playlistVideos.findIndex(data => data.id === props.video.id);
          if(index < playlistVideos.length-1){
            props.setVideo(playlistVideos[index+1])
            props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:playlistVideos[index+1].id})
            localStorage.setItem('video_id', playlistVideos[index+1].id)
          }
        }
      }
    
    const prevHandler = () => {
        if (playlistVideos && playlistVideos.length){
            let index = playlistVideos.findIndex(data => data.id === props.video.id);
            if(index > 0){
              props.setVideo(playlistVideos[index-1])
              props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:playlistVideos[index-1].id})
              localStorage.setItem('video_id', playlistVideos[index-1].id)
            }
          }
        setPlaying(true)
      }

    const startHandler = () => {
        setTimeout(()=>{
          if(props.playerRef && props.playerRef.current && props.video) {
            let current_time = Math.round(props.playerRef.current.getCurrentTime())
            if(props.video){
              if (props.video.duration-30<current_time){
                  time = Math.floor((props.video.duration-30)/5)*5
              } else {
                  time = Math.floor(current_time/5)*5
              }
              dispatch(getAnalyzeInfo(props.video.id, current_time, time))
            }
          }
        }, 4500)
      }

    const mouseMove = (e) => {
        setMenuVisible(true)
        setMouse(true)
        setTimeout(() => {
            setMouse(false); 
        }, 500)
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }

    const handleProgress = (changeState) => { 
        let t = props.playerRef.current?.getCurrentTime()
        t && setTimeDisplay(toTimeString(t)) 
        let progressInPercentage = changeState.playedSeconds/props.video.duration*100
        setVideoProgress(progressInPercentage)
    }

    const readyHandler = () => {
        if(videoUrl && props.playerRef && props.playerRef.current){ 
            const internalPlayer = props.playerRef.current?.getInternalPlayer('hls')
            const levels = internalPlayer?.levels
            if(internalPlayer.currentLevel === -1){
                internalPlayer.currentLevel = 0
                let currentQuality = levels[0]
                setQuality(currentQuality.height)
            }
        }
    }

    return (
        <>
            <div className = 'position-relative'
                ref = {playerContainerRef}
                onMouseMoveCapture={mouseMove}
                onMouseLeave={()=>setMenuVisible(false)}
                onTouchStart={mouseMove}
                style={{aspectRatio:"16/7"}}
            >   
                <div className='position-absolute w-100 h-100'>
                    <ReactPlayer
                        ref = {props.playerRef}
                        className='react-player'
                        // controls
                        url = {videoUrl}
                        onEnded = {endHandler}
                        onStart={()=>startHandler()}
                        onReady={()=>readyHandler()}
                        // onPlay={()=>setPlaying(true)}
                        onPause={()=>setPlaying(false)}
                        onProgress = {handleProgress}
                        playing = {playing}
                        volume = {sound}
                        muted = {muted}
                        // light = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=600`}
                        config={{
                        file: { 
                            attributes: {
                            controlsList: 'nodownload noprops.fullscreen'
                            }
                        }
                        }}
                        width="100%"
                        height='100%'
                    />  
                </div>

                {/* custom controls and player features */}
                <PlayerOverlay
                    playing = {playing}
                    setPlaying = {setPlaying}
                    endHandler = {endHandler}
                    prevHandler = {prevHandler}
                    video = {props.video}
                    setVideo = {props.setVideo}
                    menuVisible = {menuVisible}
                    setMenuVisible = {setMenuVisible}
                    playlistVideos = {playlistVideos}
                    playerRef = {props.playerRef}
                    playerContainerRef = {playerContainerRef}
                    videoProgress = {videoProgress}
                    onVideoChange = {onVideoChange}
                    onSoundChange = {onSoundChange}
                    soundProgress = {soundProgress}
                    timeDisplay = {timeDisplay}
                    toTimeString = {toTimeString}
                    videoUrl = {videoUrl}
                    quality = {quality}
                    setQuality = {setQuality}
                    searchParams = {props.searchParams}
                    setSearchParams = {props.setSearchParams}
                    muted = {muted}
                    setMuted = {setMuted}
                    handleRewind = {props.handleRewind}
                />
            </div>
        </>
    );
};

export default CustomPlayer;